import { NativeStackHeaderProps } from "@react-navigation/native-stack";

import YourCareTeamScreen from "../screens/dashboard/YourCareTeamScreen";
import BookAppointmentScreen from "../screens/dashboard/BookAppointmentScreen";
import ChatScreen from "../screens/dashboard/ChatScreen";
import MyAppointmentsScreen from "../screens/dashboard/MyAppointmentsScreen";
import RateAppointmentScreen from "../screens/dashboard/RateAppointment/RateAppointmentScreen";

import MyGoalsScreen from "../screens/dashboard/MyGoalsScreen";
import EditGoalScreen from "../screens/dashboard/EditGoalScreen";
import AddGoalScreen from "../screens/dashboard/AddGoalScreen";
import GoalDetailsScreen from "../screens/dashboard/GoalDetailsScreen";
import AddGoalCategoryScreen from "../screens/dashboard/AddGoalCategoryScreen";
import NavigationBar from "../components/ui/navigation/NavigationBar";
import { ScreenTypeEnum } from "../hooks/useScreenType";
import { StackActions } from "@react-navigation/native";
import { useEffect, useLayoutEffect } from "react";
import LocalizedStrings from "../helpers/LocalizedStrings";
import ConfirmAppointmentScreen from "../screens/dashboard/ConfirmAppointmentScreen";
import { makeStyles } from "@rneui/themed";
import { EdgeInsets, useSafeAreaInsets } from "react-native-safe-area-context";
import {
  BackHandler,
  Platform,
  ScaledSize,
  useWindowDimensions
} from "react-native";
import { useNetInfo } from "@react-native-community/netinfo";
import BookAppointmentConfirmationScreen from "../screens/dashboard/BookAppointmentConfirmationScreen";

interface HeaderProps extends NativeStackHeaderProps {
  rounded?: boolean;
  hideMenuToggleOnTablet?: boolean;
  hideBackButton?: boolean;
}

export const Header = (props: HeaderProps) => <NavigationBar {...props} />;

export const ScreenOrientation = (type: ScreenTypeEnum) =>
  type === ScreenTypeEnum.TABLET ? "all" : "portrait";

export type YourCareTeamNavigationType = {
  YourCareTeam: undefined;
  Chat: undefined;
  VideoCall: { code: string };
  RateAppointment: undefined;
};

export type AppointmentsNavigationType = {
  BookAppointment: { event_id: string; isReschedule: boolean };
  BookAppointmentConfirmation: {
    event_id: string;
    startDate: string;
    endDate: string;
    staff_id: string;
  };
  MyAppointments: undefined;
  ConfirmAppointment: { appointment_id: string };
};

export type GoalsNavigationType = {
  MyGoals: undefined;
  EditGoal: undefined;
  GoalDetails: undefined;
  AddGoalCategory: undefined;
  AddGoal: undefined;
};

export const usePreventBackNavigation = (navigation, enabled) => {
  useEffect(() => {
    if (!enabled) {
      navigation.setOptions({ headerBackVisible: true, gestureEnabled: true });
      return;
    }
    const backHandler = BackHandler.addEventListener(
      "hardwareBackPress",
      () => true
    );
    navigation.setOptions({ headerBackVisible: false, gestureEnabled: false });

    return () => {
      navigation.setOptions({ headerBackVisible: true, gestureEnabled: false });
      backHandler.remove();
    };
  }, [enabled]);
};

export const useHideTabBar = (navigation, skip) => {
  const netInfo = useNetInfo();
  const insets = useSafeAreaInsets();
  const dimensions = useWindowDimensions();
  const styles = useTabBarStyles({ insets, dimensions });
  useLayoutEffect(() => {
    if (skip && netInfo.isInternetReachable) return;

    const tabBar = navigation.getParent();
    if (tabBar === undefined) return;

    tabBar.setOptions({
      tabBarStyle: { display: "none" }
    });

    return () => {
      tabBar.setOptions({
        tabBarStyle: styles.tabBar
      });
    };
  }, [netInfo]);
};

export const AppointmentsNavigationFlow = (Stack) => [
  <Stack.Screen
    key="ConfirmAppointmentScreen"
    name="ConfirmAppointment"
    component={ConfirmAppointmentScreen}
    options={{ title: LocalizedStrings.screens.confirmAppointment.title }}
  />,
  <Stack.Screen
    key="BookAppointment"
    name="BookAppointment"
    component={BookAppointmentScreen}
    options={{ title: LocalizedStrings.screens.bookAppointment.title }}
  />,
  <Stack.Screen
    key="BookAppointmentConfirmation"
    name="BookAppointmentConfirmation"
    component={BookAppointmentConfirmationScreen}
    options={{
      title: LocalizedStrings.screens.bookAppointmentConfirmation.title
    }}
  />,
  <Stack.Screen
    key="MyAppointments"
    name="MyAppointments"
    component={MyAppointmentsScreen}
    options={{ title: LocalizedStrings.screens.myAppointments.title }}
  />
];

export const YourCareTeamNavigationFlow = (Stack) => [
  <Stack.Screen
    key="YourCareTeam"
    name="YourCareTeam"
    component={YourCareTeamScreen}
    options={{ title: LocalizedStrings.screens.yourCareTeam.title }}
  />,

  <Stack.Screen key="Chat" name="Chat" component={ChatScreen} />,
  <Stack.Screen
    key="RateAppointment"
    name="RateAppointment"
    component={RateAppointmentScreen}
    options={{ title: LocalizedStrings.screens.rateAppointment.title }}
  />
];

export const GoalsNavigationFlow = (Stack) => [
  <Stack.Screen
    key="MyGoals"
    name="MyGoals"
    component={MyGoalsScreen}
    options={{ title: LocalizedStrings.screens.goals.title }}
  />,
  <Stack.Screen
    key="GoalDetails"
    name="GoalDetails"
    component={GoalDetailsScreen}
    options={{ title: "Goal Title" }}
  />,
  <Stack.Screen
    key="EditGoal"
    name="EditGoal"
    component={EditGoalScreen}
    options={{ title: LocalizedStrings.screens.goals.editGoal }}
  />,
  <Stack.Screen
    key="AddGoalCategory"
    name="AddGoalCategory"
    component={AddGoalCategoryScreen}
    options={{ title: LocalizedStrings.screens.goals.addNewGoal }}
  />,
  <Stack.Screen
    key="AddGoal"
    name="AddGoal"
    component={AddGoalScreen}
    options={{ title: LocalizedStrings.screens.goals.addNewGoal }}
  />
];

export const resetTabStacks = (navigation) => {
  const state = navigation.getState();

  state?.routes?.forEach((route) => {
    if (route.state?.index > 0) {
      navigation.dispatch(StackActions.popToTop());
    }
  });
};

const TABBAR_HEIGHT = 70;
export const useTabBarStyles = makeStyles(
  (
    theme,
    { insets, dimensions }: { insets: EdgeInsets; dimensions: ScaledSize }
  ) => {
    const bottomInsets = insets.bottom + (Platform.OS === "android" ? 10 : 0);
    const marginBottom = Math.max(bottomInsets, 10);

    const isDefaultTabBar = dimensions.fontScale >= 2;

    return {
      tabBarBackground: {
        backgroundColor: theme.colors.background,
        marginBottom
      },
      tabBar: {
        height: isDefaultTabBar ? TABBAR_HEIGHT + marginBottom : TABBAR_HEIGHT,
        marginLeft: isDefaultTabBar ? 0 : 20,
        marginRight: isDefaultTabBar ? 0 : 20,
        marginBottom: isDefaultTabBar ? 0 : marginBottom,
        borderRadius: isDefaultTabBar ? 0 : TABBAR_HEIGHT / 2,
        backgroundColor: theme.colors.darkBlue,
        paddingBottom: isDefaultTabBar ? 0 : 5,
        marginHorizontal: isDefaultTabBar ? 0 : 10,
        paddingHorizontal: isDefaultTabBar ? 0 : 5,
        flexDirection: "row"
      },
      tabBarItem: {
        flex: 1,
        height: isDefaultTabBar ? TABBAR_HEIGHT + 10 : TABBAR_HEIGHT - 10,
        borderRadius: isDefaultTabBar ? 0 : (TABBAR_HEIGHT - 10) / 2,
        borderWidth: isDefaultTabBar ? 0 : 2,
        borderColor: isDefaultTabBar ? "transparent" : theme.colors.white,
        marginVertical: isDefaultTabBar ? 0 : 5,
        alignItems: "center",
        justifyContent: "center"
      },
      tabBarItemTitle: {
        color: theme.colors.white
      },
      tabBarSelectedStyle: {
        backgroundColor: isDefaultTabBar && "#00000044"
      },
      tabBarNotSelectedStyle: {
        borderColor: !isDefaultTabBar && "transparent"
      }
    };
  }
);
