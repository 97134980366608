import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { useTheme } from "@rneui/themed";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import Feather from "react-native-vector-icons/Feather";

import DashboardStackNavigator from "./DashboardStackNavigator";
import MyAccountStackNavigator from "./MyAccountStackNavigator";

import Text from "../components/ui/Text";
import ReadingsStackNavigator from "./ReadingsStackNavigator";
import { useTabBarStyles } from "./NavigationUtils";
import LocalizedStrings from "../helpers/LocalizedStrings";
import { useEffect, useState } from "react";
import { useWindowDimensions } from "react-native";

export type TabsParamList = {
  Home: undefined;
  Readings: undefined;
  Account: undefined;
};

const getTabBarIconAndName = (routeName: string) => {
  let iconName = "";
  let tabName = "";
  if (routeName === "Home") {
    iconName = "home";
    tabName = LocalizedStrings.screens.tab.home;
  } else if (routeName === "Readings") {
    iconName = "bar-chart-2";
    tabName = LocalizedStrings.screens.tab.readings;
  } else if (routeName === "Account") {
    iconName = "user";
    tabName = LocalizedStrings.screens.tab.account;
  }

  return { iconName, tabName };
};

const Tab = createBottomTabNavigator<TabsParamList>();

function TabNavigator() {
  const { theme } = useTheme();
  const insets = useSafeAreaInsets();
  const dimensions = useWindowDimensions();
  const styles = useTabBarStyles({ insets, dimensions });

  return (
    <Tab.Navigator
      screenOptions={({ route, navigation }) => {
        const [isRootScreen, setIsRootScreen] = useState<boolean>(true);

        const { iconName } = getTabBarIconAndName(route.name);

        const state = navigation.getState();
        const currentRoute = state.routeNames[state.index];
        const focused = navigation.isFocused() && isRootScreen;

        // Removes the white border of the tab bar when stack index > 0.
        const callBackFn = (e) => {
          const { data } = e;

          const currentRouteState = data?.state?.routes?.find(
            (item) => item?.name === currentRoute
          );
          setIsRootScreen(currentRouteState?.state?.index === 0);
        };
        useEffect(() => {
          if (currentRoute !== route.name) return;
          navigation.addListener("state", callBackFn);

          return () => {
            navigation.removeListener("state", callBackFn);
          };
        }, [navigation, focused, currentRoute, route]);

        return {
          headerShown: false,
          tabBarStyle: styles.tabBar,
          tabBarLabel: ({ children }) => {
            return (
              <Text capsSmall style={styles.tabBarItemTitle}>
                {children}
              </Text>
            );
          },
          tabBarItemStyle: [
            styles.tabBarItem,
            focused ? styles.tabBarSelectedStyle : styles.tabBarNotSelectedStyle
          ],

          tabBarIcon: () => (
            <Feather name={iconName} size={24} color={theme.colors.white} />
          )
        };
      }}
      //tabBar={(props) => <TabBar {...props} />}
    >
      <Tab.Screen
        name="Home"
        options={{ tabBarAccessibilityLabel: "Home" }}
        component={DashboardStackNavigator}
      />
      <Tab.Screen
        name="Readings"
        options={{ tabBarAccessibilityLabel: "Readings" }}
        component={ReadingsStackNavigator}
      />
      <Tab.Screen
        name="Account"
        options={{ tabBarAccessibilityLabel: "Account" }}
        component={MyAccountStackNavigator}
      />
    </Tab.Navigator>
  );
}

export default TabNavigator;
